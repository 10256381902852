import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

type NewsCardProps = {
  title: string;
  eyeCatchData: any;
  url: string;
  tagName: string;
  description: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: `1rem auto`,
      maxWidth: 480,
      [theme.breakpoints.up(`md`)]: {
        maxWidth: `none`,
        display: `flex`,
      },
    },
    cardBox: {
      [theme.breakpoints.up(`md`)]: {
        display: `flex`,
      },
    },
    media: {
      maxHeight: 240,
      maxWidth: 480,
      [theme.breakpoints.up(`md`)]: {
        height: `100%`,
        maxWidth: `none`,
        width: 180,
      },
    },
    imageSize: {
      maxHeight: 240,
      maxWidth: 480,
      [theme.breakpoints.up(`md`)]: {
        height: `100%`,
        width: 180,
      },
    },
    listChipStyle: {
      margin: `0 auto`,
    },
    descStyle: {
      padding: `4px`,
    },
  }),
);

const NewsCard: React.VFC<NewsCardProps> = ({
  title,
  eyeCatchData,
  url,
  tagName,
  description,
}) => {
  const eyeCatch = getImage(eyeCatchData);
  const classes = useStyles();

  const handleClick = () => navigate(`/news/${url}`);

  return (
    <Card elevation={4} className={classes.card}>
      <CardActionArea onClick={handleClick} className={classes.cardBox}>
        <CardMedia className={classes.media}>
          <GatsbyImage
            image={eyeCatch}
            alt={`${url} eye catch`}
            className={classes.imageSize}
          />
        </CardMedia>
        <CardContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Chip
                color="primary"
                label={tagName}
                className={classes.listChipStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body2" className={classes.descStyle}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NewsCard;
