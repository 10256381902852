import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import BottomMenu from '@/components/common/section/BottomMenu';
import NewsListBox from '@/components/news/section/NewsListBox';
import StaticSEO from '@/components/common/section/StaticSEO';

const News: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="お知らせ"
      descriptionData="鈴木弘基税理士事務所より発信している情報は、こちらのページにまとめています。"
      OgImageData="News"
    />
    <FirstView pageTitle="お知らせ" fileName="News" />
    <NewsListBox />
    <BottomMenu />
  </Layout>
);

export default News;
