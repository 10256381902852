import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import NewsCard from '../middle/NewsCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newsCardsBox: {
      padding: `1rem 0`,
      marginTop: `-3rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 20%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const NewsListBox: React.VFC = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulNewsPost(
        filter: { url: { ne: "dummy" } }
        sort: { order: DESC, fields: createdAt }
      ) {
        edges {
          node {
            url
            title
            description
            tag {
              title
            }
            eyeCatch {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const posts = data.allContentfulNewsPost.edges;

  const classes = useStyles();

  return (
    <section className={classes.newsCardsBox}>
      <Grid container spacing={3} className={classes.cardsWrapper}>
        {posts.length ? (
          posts.map((post) => (
            <Grid item xs={12} sm={6} md={6}>
              <NewsCard
                title={post.node.title}
                eyeCatchData={post.node.eyeCatch}
                url={post.node.url}
                tagName={post.node.tag.title}
                description={post.node.description}
              />
            </Grid>
          ))
        ) : (
          <Typography>まだ投稿がありません。</Typography>
        )}
      </Grid>
    </section>
  );
};

export default NewsListBox;
